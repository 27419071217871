/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.use {
  margin-top: -0.2rem;
}
.use .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.4rem;
}
.use .content img {
  width: 1.5rem;
}
.use .content .img {
  display: block;
}
.use .content .img-hover {
  display: none;
}
.use .content .title {
  font-size: 1rem;
  font-weight: 700;
  color: #2DBE60;
}
.use .content .title small {
  font-size: 0.4rem;
  font-weight: 300;
}
.use .content .sub-content {
  font-size: 0.24rem;
  font-weight: 300;
  color: #999999;
  margin-top: -0.2rem;
}
