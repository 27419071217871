html {
  /* font-size: 625%; */
  /*10 ÷ 16 × 100% = 62.5%*/
  font-size: 100px;
  /* FILTER: gray;
  -webkit-filter: grayscale(100%); */
}
/* 计算方法是 320/750,得出比率，750是设计稿的宽度*/
/* @media screen and (min-width: 320px) {html{font-size:42.66px;}}
@media screen and (min-width: 384px) {html{font-size:51.2px;}}
@media screen and (min-width: 480px) {html{font-size:64px;}}
@media screen and (min-width: 640px) {html{font-size:85.33px;}} */
/* 下面这算出来偏小 */
/* @media screen and (min-width: 240px) {html{font-size:12.5px;}}
@media screen and (min-width: 320px) {html{font-size:16.6667px;}}
@media screen and (min-width: 352px) {html{font-size:18.3333px;}}
@media screen and (min-width: 360px) {html{font-size:18.75px;}}
@media screen and (min-width: 375px) {html{font-size:19.53125px;}}
@media screen and (min-width: 400px) {html{font-size:20.8333px;}}
@media screen and (min-width: 414px) {html{font-size:21.5625px;}}
@media screen and (min-width: 440px) {html{font-size:22.9167px;}}
@media screen and (min-width: 480px) {html{font-size:25px;}}
@media screen and (min-width: 520px) {html{font-size:27.0833px;}}
@media screen and (min-width: 560px) {html{font-size:29.1667px;}}
@media screen and (min-width: 600px) {html{font-size:31.25px;}}
@media screen and (min-width: 640px) {html{font-size:33.3333px;}}
@media screen and (min-width: 680px) {html{font-size:35.4167px;}}
@media screen and (min-width: 720px) {html{font-size:37.5px;}}
@media screen and (min-width: 760px) {html{font-size:39.5833px;}}
@media screen and (min-width: 800px) {html{font-size:41.6667px;}}
@media screen and (min-width: 960px) {html{font-size:50px;}}
@media screen and (min-width: 1120px) {html{font-size:58.3333px;}}
@media screen and (min-width: 1440px) {html{font-size:75px;}}
@media screen and (min-width: 1660px) {html{font-size:86.4583px;}}
@media screen and (min-width: 1760px) {html{font-size:91.6667px;}}
@media screen and (min-width: 1920px) {html{font-size:100px;}}
@media screen and (min-width: 2048px) {html{font-size:106.6667px;}}
@media screen and (min-width: 2160px) {html{font-size:112.5px;}}
@media screen and (min-width: 2560px) {html{font-size:133.3333px;}}
@media screen and (min-width: 2880px) {html{font-size:150px;}}
@media screen and (min-width: 3200px) {html{font-size:166.6667px;}}
@media screen and (min-width: 3440px) {html{font-size:179.1667px;}}
@media screen and (min-width: 3840px) {html{font-size:200px;}}
@media screen and (min-width: 4096px) {html{font-size:213.3333px;}}
@media screen and (min-width: 5120px) {html{font-size:266.6667px;}}
@media screen and (min-width: 6400px) {html{font-size:333.3333px;}}
@media screen and (min-width: 7680px) {html{font-size:4px;}} */
@media screen and (min-width: 240px) {
  html {
    font-size: 27.5px;
  }
}
@media screen and (min-width: 320px) {
  html {
    font-size: 31.6667px;
  }
}
@media screen and (min-width: 352px) {
  html {
    font-size: 33.3333px;
  }
}
@media screen and (min-width: 360px) {
  html {
    font-size: 33.75px;
  }
}
@media screen and (min-width: 375px) {
  html {
    font-size: 34.53125px;
  }
}
@media screen and (min-width: 400px) {
  html {
    font-size: 35.8333px;
  }
}
@media screen and (min-width: 414px) {
  html {
    font-size: 36.5625px;
  }
}
@media screen and (min-width: 440px) {
  html {
    font-size: 37.9167px;
  }
}
@media screen and (min-width: 480px) {
  html {
    font-size: 40px;
  }
}
@media screen and (min-width: 520px) {
  html {
    font-size: 42.0833px;
  }
}
@media screen and (min-width: 560px) {
  html {
    font-size: 44.1667px;
  }
}
@media screen and (min-width: 600px) {
  html {
    font-size: 46.25px;
  }
}
@media screen and (min-width: 640px) {
  html {
    font-size: 48.3333px;
  }
}
@media screen and (min-width: 680px) {
  html {
    font-size: 50.4167px;
  }
}
@media screen and (min-width: 720px) {
  html {
    font-size: 52.5px;
  }
}
@media screen and (min-width: 750px) {
  html {
    font-size: 53.5px;
  }
}
@media screen and (min-width: 760px) {
  html {
    font-size: 54.5833px;
  }
}
@media screen and (min-width: 800px) {
  html {
    font-size: 56.6667px;
  }
}
@media screen and (min-width: 960px) {
  html {
    font-size: 65px;
  }
}
@media screen and (min-width: 1120px) {
  html {
    font-size: 73.3333px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 90px;
  }
}
@media screen and (min-width: 1660px) {
  html {
    font-size: 93.4583px;
  }
}
@media screen and (min-width: 1760px) {
  html {
    font-size: 96.6667px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 100px;
  }
}
@media screen and (min-width: 2048px) {
  html {
    font-size: 106.6667px;
  }
}
@media screen and (min-width: 2160px) {
  html {
    font-size: 112.5px;
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: 133.3333px;
  }
}
@media screen and (min-width: 2880px) {
  html {
    font-size: 150px;
  }
}
@media screen and (min-width: 3200px) {
  html {
    font-size: 166.6667px;
  }
}
@media screen and (min-width: 3440px) {
  html {
    font-size: 179.1667px;
  }
}
@media screen and (min-width: 3840px) {
  html {
    font-size: 200px;
  }
}
@media screen and (min-width: 4096px) {
  html {
    font-size: 213.3333px;
  }
}
@media screen and (min-width: 5120px) {
  html {
    font-size: 266.6667px;
  }
}
@media screen and (min-width: 6400px) {
  html {
    font-size: 333.3333px;
  }
}
@media screen and (min-width: 7680px) {
  html {
    font-size: 4px;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Fira Sans', 'Source Sans Pro', sans-serif;
  font-size: 0.14rem;
  box-sizing: border-box;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
