.service .content {
  background: #ffffff;
  box-shadow: -0.02rem 0.35rem 0.56rem 0 #f0f2f4;
  border-radius: 0.14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.32rem 0.6rem 0.48rem;
}
.service .content img {
  width: 1.5rem;
}
.service .content .img {
  display: block;
}
.service .content .img-hover {
  display: none;
}
.service .content .title {
  font-size: 0.36rem;
  font-weight: 500;
  color: #666666;
  margin: 0.58rem auto 0.4rem;
}
.service .content .sub-content {
  font-size: 0.22rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 0.4rem;
}
.service .content:hover {
  background: #2dbe60;
  box-shadow: -0.02rem 0.35rem 0.56rem 0 rgba(45, 190, 96, 0.44);
  border-radius: 0.14rem;
  cursor: pointer;
}
.service .content:hover .img {
  display: none;
}
.service .content:hover .img-hover {
  display: block;
}
.service .content:hover .title {
  color: #ffffff;
}
.service .content:hover .sub-content {
  color: #ffffff;
}
