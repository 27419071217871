/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.team2 {
  box-shadow: 0px 31px 74px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 6rem;
  margin: 0 0.05rem;
  background-color: #fff;
  width: 25%;
}
.team2 .img2 {
  width: 0.93rem;
}
.team2 .img2-content {
  margin-top: -0.93rem;
}
.team2 .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team2 .content .img {
  width: 2rem;
  margin: 0.24rem 0.5rem 0;
}
.team2 .content .name {
  font-size: 0.36rem;
  font-weight: 500;
  color: #666666;
  padding: 0.22rem 0 0.04rem;
}
.team2 .content .title {
  font-size: 0.24rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.33rem;
  padding: 0 0 0.16rem;
}
.team2 .content .intro {
  font-size: 0.18rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.3rem;
  padding: 0 0.3rem 0 0.24rem;
  width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .team2 .content {
    /* IE10-specific styles go here */
  }
}
@media all and (-ms-high-contrast: none) {
  .team2 .content .intro {
    min-height: 4.5rem;
  }
}
@media (max-width: 768px) {
  .team2 {
    height: 9rem;
    width: 4rem;
    margin: 0 0.05rem 0.3rem;
  }
  .team2 .content .intro {
    line-height: 0.66rem;
  }
}
@media (max-width: 650px) {
  .team2-warp {
    flex-flow: wrap;
  }
  .team2 {
    margin: 0 0.4rem 0.3rem;
  }
}
