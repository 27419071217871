.my-marquee-page {
  height: 4.01rem;
  position: relative;
}
.my-marquee-page .marquee-landscape-txt {
  font-size: 0.2rem;
  position: relative;
  font-family: 'Source Sans Pro';
  width: 3rem;
  padding: 0;
  margin: 0 0.25rem;
  box-shadow: -1px 17px 27px 2px #f0f2f4;
}
.my-marquee-page .marquee-landscape-txt .title {
  font-weight: 700;
  position: absolute;
  color: #4e4e4e;
  bottom: 0.88rem;
  left: 0.22rem;
}
.my-marquee-page .marquee-landscape-txt .date {
  font-weight: 300;
  color: #9c9c9c;
  position: absolute;
  bottom: 0.44rem;
  left: 0.22rem;
}
