/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.my-product .product {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}
.my-product .product .text {
  margin: 0 1.2rem;
}
.my-product .product .text .title {
  font-size: 0.48rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 0.2rem;
  font-family: 'Source Sans Pro', sans-serif;
}
.my-product .product .text .title:before {
  width: 0.09rem;
  height: 0.48rem;
  background: #2DBE60;
  border-radius: 0.05rem;
  content: '';
  display: inline-block;
  margin-right: 0.1rem;
  top: 0.08rem;
  position: relative;
}
.my-product .product .text .sub-title {
  font-size: 0.26rem;
  color: #666;
  margin-bottom: 0.3rem;
}
.my-product .product .text .text-content {
  font-size: 0.18rem;
  color: #666;
}
.my-product .product .img img {
  width: 5.7rem;
}
@media (max-width: 768px) {
  .my-product .product .text {
    margin: 0 0.15rem;
  }
}
