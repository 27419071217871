.react-marquee-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.react-marquee-box .marquee-landscape-wrap {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.react-marquee-box .marquee-landscape-wrap .marquee-landscape-item {
  float: left;
  height: 100%;
  white-space: nowrap;
  /* display: flex; */
}
.react-marquee-box .marquee-landscape-wrap .marquee-landscape-item .marquee-landscape-txt {
  /* padding:0 10px; */
  white-space: nowrap;
  display: inline-block;
  height: 100%;
  /* width: 200px;; */
}
.react-marquee-box .marquee-vertical-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.react-marquee-box .marquee-vertical-wrap .marquee-vertical-item .marquee-vertical-txt {
  height: 60px;
  line-height: 60px;
}
