/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
a {
  color: #2DBE60;
}
a:active {
  color: #389e0d;
}
a:hover {
  color: #73d13d;
}
img {
  width: 100%;
}
.font16 {
  font-size: 0.16rem;
}
.font11 {
  font-size: 0.11rem;
}
ol,
ul,
dl {
  margin: 0;
  padding: 0;
}
.ant-btn-primary {
  background-color: #2DBE60;
  border-color: #2DBE60;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #197539;
  border-color: #197539;
  color: #ffffff;
}
.ant-btn-link {
  color: #2DBE60;
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #197539;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #197539;
}
.ant-back-top-icon {
  background-size: 100%;
}
