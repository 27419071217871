/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.my-varieties {
  background: #ffffff;
  box-shadow: -1px 17px 27px 0px #f0f2f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.62rem 0 0.2rem;
  margin-bottom: 80px;
  color: #666;
}
.my-varieties .sub-img {
  max-width: 200px;
}
.my-varieties .title {
  font-size: 0.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.my-varieties .subtitle {
  font-size: 0.16rem;
  margin: 0.12rem 0 0.24rem;
}
.my-varieties .more {
  color: #999999;
  font-size: 0.48rem;
  font-weight: 600;
}
