/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.my-solution .container .userinfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -0.34rem;
}
.my-solution .container .userinfo .user-img img {
  width: 0.6rem;
}
.my-solution .container .userinfo .user-content {
  color: #666;
  padding: 0 0.3rem;
  font-size: 0.24rem;
  font-weight: 500;
}
.my-solution .container .img {
  margin: 0.6rem 0 0.5rem;
}
.my-solution .container .text {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  color: #666666;
  font-size: 0.2rem;
  font-weight: 400;
  padding-bottom: 0.8rem;
  line-height: 0.32rem;
}
.my-solution .container .text .left-text {
  width: 3.57rem;
}
.my-solution .container .text .right-text {
  width: 3.57rem;
}
