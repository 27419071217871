.download .container .c7 {
  background: #ffffff;
  box-shadow: -1px 17px 27px 0px #f0f2f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.download .container .c7 .c7-title {
  padding: 0.4rem 0 0.28rem;
  font-size: 0.27rem;
  font-weight: 500;
  color: #000000;
  line-height: 0.3rem;
}
.download .container .c7 .c7-date {
  font-weight: 400;
  font-size: 0.19rem;
  color: #666666;
  line-height: 0.3rem;
  padding-bottom: 0.09rem;
}
.download .container .c7 .c7-desc {
  font-weight: 400;
  color: #666666;
  line-height: 0.3rem;
  padding-bottom: 0.09rem;
  font-size: 0.19rem;
}
.download .container .c7 .c7-time {
  font-weight: 400;
  color: #666666;
  line-height: 0.3rem;
  height: 0.3rem;
  margin-bottom: 0.29rem;
  font-size: 0.19rem;
}
.download .container .c7 .c7-btn {
  padding-bottom: 0.24rem;
}
.download .container .c7 .c7-btn button {
  height: 0.74rem;
  border-radius: 0.74rem;
  font-size: 0.3rem;
  font-weight: 500;
  padding: 0 0.51rem;
}
.download .container .developer {
  width: 100%;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
  color: #999;
}
.download .container .developer .policy {
  padding: 0 0.16rem;
}
.download .container .developer .divide {
  width: 1px;
  height: 0.12rem;
  background: #999;
}
