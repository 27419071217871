/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.home-banner {
  background: url('../../../common/images/home/banner.jpg');
  background-size: cover;
  background-position: center;
  font-family: PingFangSC-Light, PingFang SC;
}
.home-banner .newBannerTitle {
  color: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 7.93rem;
  margin-right: 3rem;
}
.home-banner .newBannerTitle .banner-content1 {
  position: relative;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) {
  position: relative;
  margin: -0.64rem 0 0 0.64rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0.12rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
  font-size: 0.24rem;
  font-weight: normal;
  margin-bottom: 0.28rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) img {
  width: 2.32rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 0.12rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) > div {
  font-size: 0.24rem;
  font-weight: 500;
  margin-bottom: 0.16rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) > div > span {
  color: #ff7e19;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) > div > span:nth-child(2) {
  margin-left: 0.16rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(1) {
  font-size: 0.24rem;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0.42rem;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(1) > span {
  font-size: 0.24rem;
  color: #ff7e19;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) {
  position: relative;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input {
  width: 6.2rem;
  height: 0.48rem;
  font-size: 0.2rem;
  font-weight: 700;
  color: #ffffff;
  background: transparent;
  border-radius: 0.08rem;
  margin: 0 0.08rem 0 0;
  padding: 0 0 0 0.12rem;
  border: 0.02rem solid #ffffff;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input::-webkit-input-placeholder {
  font-size: 0.16rem;
  color: #ffffff;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input:focus {
  border: 0.02rem solid #ffffff;
  outline-color: #2DBE60;
  outline: 0;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input.errorPhoneFormat {
  border: 1px solid #ff4545 !important;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input:-ms-input-placeholder,
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) textarea:-ms-input-placeholder {
  font-weight: 400;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input::-webkit-input-placeholder,
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) textarea::-webkit-input-placeholder {
  font-weight: 400;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button {
  position: absolute;
  right: 0.12rem;
  top: 0.04rem;
  height: 0.4rem;
  font-size: 0.2rem;
  font-weight: 500;
  color: #ffffff;
  background: #04bf53;
  border-radius: 0.06rem;
  border: 0;
  cursor: pointer;
  padding: 0 0.24rem;
  font-weight: bold;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button:focus {
  border: 0rem solid #2DBE60;
  outline-color: #2DBE60;
  outline: 0;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button:hover {
  background: #36b169;
}
.home-banner .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(3) {
  margin-top: 0.08rem;
  color: #ff4545;
  font-weight: 700;
  font-size: 0.18rem;
}
.modal-apply-try {
  width: 4.2rem !important;
  background: #ffffff;
  border-radius: 0.24rem;
  margin: 0 auto;
  padding: 0;
}
.modal-apply-try .ant-modal-content {
  border-radius: 0.24rem;
}
.modal-apply-try .ant-modal-content .ant-modal-body {
  padding: 0;
  height: 2.9rem;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap {
  text-align: center;
  position: relative;
  top: -1.44rem;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(1) img {
  width: 3.15rem;
  height: 3.15rem;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(2) {
  font-size: 0.18rem;
  font-weight: 400;
  color: #666666;
  margin-bottom: 0.4rem;
  margin-top: -0.4rem;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(2) span {
  color: #ff7e19;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try {
  width: 1.83rem;
  height: 0.45rem;
  background: #1d9e60;
  border-radius: 0.23rem;
  border: 0;
  cursor: pointer;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try span {
  width: 0.6rem;
  height: 0.28rem;
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try:focus {
  border: 0rem solid #2DBE60;
  outline-color: #2DBE60;
  outline: 0;
}
.modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try:hover {
  background: #148e53;
}
@media (max-width: 768px) {
  .home-banner {
    background: url('../../../common/images/home/banner-mobile.png');
    background-size: cover;
  }
  .home-banner > div {
    padding: 0;
  }
  .home-banner > div .newBannerTitle {
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding: 3.02803738rem 0 2.1682243rem;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) {
    margin: 0;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    font-size: 1.9rem;
    position: relative;
    z-index: 2;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
    font-size: 0.6728972rem;
    margin-left: 0;
    margin-bottom: 0.8411215rem;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) img {
    width: 6.87850467rem;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) > div {
    font-size: 0.57943925rem;
    line-height: 1.7;
    margin-bottom: 0.37383178rem;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div:nth-child(2) > div.home-qrcode:nth-child(2) {
    display: none;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(1) {
    font-size: 0.3364486rem;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) {
    position: relative;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input {
    width: 9.2rem;
    height: 1rem;
    font-size: 0.2rem;
    font-weight: 700;
    color: #ffffff;
    background: transparent;
    border-radius: 0.08rem;
    margin: 0 0.08rem 0 0;
    padding: 0 0 0 0.12rem;
    border: 0.02rem solid #ffffff;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input::-webkit-input-placeholder {
    font-size: 0.16rem;
    color: #ffffff;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) input:focus {
    border: 0.02rem solid #ffffff;
    outline-color: #2DBE60;
    outline: 0;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button {
    position: absolute;
    right: 0.16rem;
    top: 0.16rem;
    height: 0.8rem;
    font-size: 0.2rem;
    font-weight: 500;
    color: #ffffff;
    background: #04bf53;
    border-radius: 0.11214953rem;
    border: 0;
    cursor: pointer;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button:focus {
    border: 0rem solid #2DBE60;
    outline-color: #2DBE60;
    outline: 0;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(2) button:hover {
    background: #36b169;
  }
  .home-banner > div .newBannerTitle .banner-content1 > div:nth-child(1) > div.phone > div:nth-child(3) {
    font-size: 0.3364486rem;
  }
  .modal-apply-try {
    width: 7.85046729rem !important;
    background: #ffffff;
    border-radius: 0.44859813rem;
    margin: 0 auto;
    padding: 0;
  }
  .modal-apply-try .ant-modal-content {
    border-radius: 0.44859813rem;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body {
    padding: 0;
    height: 5.42056075rem;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap {
    text-align: center;
    position: relative;
    top: -2.69158879rem;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(1) img {
    width: 5.88785047rem;
    height: 5.88785047rem;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(2) {
    font-size: 0.3364486rem;
    font-weight: 400;
    color: #666666;
    margin-bottom: 0.74766355rem;
    margin-top: -0.74766355rem;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap > div:nth-child(2) span {
    color: #ff7e19;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try {
    width: 3.42056075rem;
    height: 0.8411215rem;
    background: #1d9e60;
    border-radius: 0.42990654rem;
    border: 0;
    cursor: pointer;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try span {
    width: 1.12149533rem;
    height: 0.52336449rem;
    font-size: 0.37383178rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try:focus {
    border: 0rem solid #2DBE60;
    outline-color: #2DBE60;
    outline: 0;
  }
  .modal-apply-try .ant-modal-content .ant-modal-body .modal-apply-try-wrap button.apply-try:hover {
    background: #148e53;
  }
}
