/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.my-role {
  background: #ffffff;
  box-shadow: -1px 17px 27px 0px #f0f2f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.24rem 0 0.4rem;
  margin-bottom: 80px;
}
.my-role .title {
  margin: 0.4rem 0 0;
  color: #666;
  font-size: 0.2rem;
  font-weight: 600;
}
